import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

//
import useCurrency from "../../../hooks/useCurrency";

//
import NumberFormat from "react-number-format";

//
import Tel from "../../../assets/img/tel.svg";
import Exit from "../../../assets/img/exit-menu.svg";

//
import { profileMenuLinks } from "../../../constances";

//
import { logoutUser } from "../../../redux/features/user/slice";
import { getUserWallets } from "../../../redux/features/user/actions";
function SideBar({
  user,
  configs,
  sidebarStatus,
  setSidebarStatus,
  logoutUser,
}) {
  const [currencyTitle] = useCurrency();

  let navigate = useNavigate();

  const [totalBalance, setTotalBalance] = useState(0);

  const getWalletList = async () => {
    await getUserWallets().then((res) => {
      if (res) {
        var totalBalance = res.reduce(function (acc, obj) {
          return acc + obj.convert_amount;
        }, 0);

        setTotalBalance(totalBalance);
      }
    });
  };

  const logout = () => {
    logoutUser();
    navigate("/app");
  };

  useEffect(() => {
    getWalletList();

    // eslint-disable-next-line
  }, []);
  return (
    <div
      className={`sidebar ${sidebarStatus ? "active" : ""}`}
      onMouseEnter={() => setSidebarStatus(true)}
      onMouseLeave={() => setSidebarStatus(true)}
    >
      <img
        src={Exit}
        alt="exit"
        className="close-icon d-block d-lg-none"
        onClick={() => setSidebarStatus(false)}
      />

      <div className="logo">
        <Link to="/app" style={{ background: `url(${configs.LOGO_HEADER})` }} />
      </div>

      <Link to="profile" className="user-info">
        <img src={user.avatar || configs.DEFAULT_CUSTOMER_AVATAR} alt="user" />
        <div>
          <p className="name mb-1">
            {user.first_name} {user.last_name}
          </p>
          <small className="credit">
            <span className="text-muted">موجودی : </span>
            <NumberFormat
              value={totalBalance}
              displayType="text"
              thousandSeparator={true}
              className="mx-1"
            />
            <small>{currencyTitle()}</small>
          </small>
        </div>
      </Link>

      <ul className="menu-link">
        {profileMenuLinks.map((item, index) => (
          <li key={index}>
            <NavLink to={item.link} end activeclassname="active">
              <img
                className="icone-menu"
                src={
                  process.env.PUBLIC_URL +
                  `/assets/svg-icons/${item.imgName}.svg`
                }
                alt={item.name}
              />
              <p>{item.name}</p>
            </NavLink>
          </li>
        ))}
        <li>
          <a className="logout pointer" onClick={() => logout()}>
            <img
              className="icone-menu"
              src={process.env.PUBLIC_URL + `/assets/svg-icons/exit.svg`}
              alt="خروج"
            />
            <p>خروج</p>
          </a>
        </li>
      </ul>
      {configs.CO_TELEPHONE && (
        <div className="phone">
          <img
            className="icone-menu"
            src={Tel}
            alt={`تلفن ${configs.BRAND_NAME}`}
          />
          <a href={`tel:${configs.CO_TELEPHONE}`}>{configs.CO_TELEPHONE}</a>
        </div>
      )}
    </div>
  );
}

SideBar.propTypes = {
  user: PropTypes.object.isRequired,
  sidebarStatus: PropTypes.bool.isRequired,
  setSidebarStatus: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  logoutUser,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
