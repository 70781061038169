import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import PropTypes from "prop-types";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Main Style
import "./App.scss";
import "./assets/fonts/font-awsome/css/all.min.css";

//
import ScrollToTop from "./layout/components/ScrollToTop";

// Auth Pages
import ThirdPartyLogin from "./pages/auth/ThirdPartyLogin";
import Login from "./pages/auth/Login";
import RegisterPassword from "./pages/auth/RegisterPassword";
import OtpRecovery from "./pages/auth/OtpRecovery";
import EmailRecovery from "./pages/auth/EmailRecovery";

// App Pages
import Dashboard from "./pages/main/Dashboard";
import CategoryInfo from "./pages/main/CategoryInfo";
import Category from "./pages/main/Category";
import ProductDetail from "./pages/main/ProductDetail";
import FAQ from "./pages/main/FAQ";
import StaticPage from "./pages/main/StaticPage";
import ContactUs from "./pages/main/ContactUs";

// Profile Pages
import Panel from "./pages/panel/Panel";
import UserInfo from "./pages/panel/UserInfo";
import Orders from "./pages/panel/Orders";
import Comments from "./pages/panel/Comments";
import Questions from "./pages/panel/Questions";
import Address from "./pages/panel/Address";
import Favorites from "./pages/panel/Favorites";
import QuotesList from "./pages/panel/QuotesList";
import Wallet from "./pages/panel/Wallet";

// Blog Pages
import MainPage from "./pages/blog/MainPage";
import Topic from "./pages/blog/Topic";
import SinglePost from "./pages/blog/SinglePost";

// Layouts
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import Cart from "./layout/Cart";
import Profile from "./layout/Profile";
import Blog from "./layout/Blog";
import NotFound from "./pages/NotFound";
import PaymentCallback from "./layout/PaymentCallback";
import EasyBitPayRedirect from "./layout/EasyBitPayRedirect";

function App() {
  /**
   * Create Cart Sesion
   */
  const createCartSession = () => {
    let cartSession = localStorage.getItem("cartSession");

    // if user dont have cart session create and store one
    if (!cartSession) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < 32; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      localStorage.setItem("cartSession", result);
    }
  };

  useEffect(() => {
    let favIconUrl = process.env.REACT_APP_FAVICON;
    if (favIconUrl) {
      let favicon = document.getElementById("favicon");
      favicon.href = favIconUrl;
    }

    createCartSession();

    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Routes>
        <Route path="/" exact element={<Navigate replace to="/app" />} />
        <Route
          path="/auth"
          exact
          element={<Navigate replace to="/auth/login" />}
        />

        {/* Auth Layout */}
        <Route path="/auth/third-party" element={<ThirdPartyLogin />} />
        <Route path="auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<RegisterPassword />} />
          <Route path="otp-recovery" element={<OtpRecovery />} />
          <Route path="email-recovery" element={<EmailRecovery />} />
          <Route path="email-recovery/:token" element={<EmailRecovery />} />
        </Route>

        {/* Main Layout */}
        <Route path="app" element={<Main />}>
          <Route path="" element={<Dashboard />} />
          <Route path="category-info/:slug" element={<CategoryInfo />} />
          <Route path="category/:slug" element={<Category />} />
          <Route path="product/:slug" element={<ProductDetail />} />
          <Route path="faq/:type" element={<FAQ />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path=":slug" element={<StaticPage />} />
        </Route>

        {/* Panel Layout */}
        <Route path="panel" element={<Profile />}>
          <Route path="" element={<Panel />} />
          <Route path="profile" element={<UserInfo />} />
          <Route path="orders" element={<Orders />} />
          <Route path="comments" element={<Comments />} />
          <Route path="question-answer" element={<Questions />} />
          <Route path="address" element={<Address />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="quotes" element={<QuotesList />} />
          <Route path="wallets" element={<Wallet />} />
        </Route>

        {/* Cart Layout */}
        <Route path="cart" element={<Cart />} />

        {/* Payment Callback */}
        <Route path="payment-callback" element={<PaymentCallback />} />
        <Route path="payments/redirect" element={<EasyBitPayRedirect />} />

        {/* Blog Layout */}
        <Route path="blog" element={<Blog />}>
          <Route path="" element={<MainPage />} />
          <Route path="topic/:slug" element={<Topic />} />
          <Route path="post/:slug" element={<SinglePost />} />
        </Route>

        {/* Not Found */}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

App.propTypes = {};

export default App;
