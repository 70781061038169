import React, { useEffect, useState } from "react";

//
import useSeo from "../../hooks/useSeo";

//
import { getUserWallets } from "../../redux/features/user/actions";

//
import { Card, Tab, Tabs } from "react-bootstrap";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

//
import MetaDecorator from "../../components/globals/MetaDecorator";
import WalletCard from "../../components/panel/WalletCard";
import WalletCardSalable from "../../components/panel/WalletCardSalable";
import WalletHistory from "../../components/panel/WalletHistory";
import ConvertWalletBalanceModal from "../../components/panel/ConvertWalletBalanceModal";
import BoxLoading from "../../components/globals/BoxLoading";
import NothingToShow from "../../components/globals/NothingToShow";

function Wallet() {
  const [seo, set_seo] = useSeo();

  const [wallets, setWallets] = useState([]);
  const [walletsIdsList, setWalletsIdsList] = useState({});
  const [loading, setLoading] = useState(false);
  const [historyKey, setHistoryKey] = useState(0);

  const [convertModal, setConvertModal] = useState(false);
  const [selectedWalletId, setSelectedWalletId] = useState(0);

  const refreshWalletsInfo = () => {
    setHistoryKey(historyKey + 1);
    getWalletList();
  };

  const getWalletList = async () => {
    // set loading
    setLoading(true);

    // request
    await getUserWallets().then((res) => {
      let list = {};
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        list[element.currency_id] = element.currency.title;
      }

      setWalletsIdsList(list);
      setWallets(res);
    });

    // stop loading
    setLoading(false);
  };

  const notSallableWallets = () => {
    let list = wallets.filter((item) => !item.currency.salable);

    return list;
  };

  const sallableWallets = () => {
    let list = wallets.filter((item) => item.currency.salable);

    return list;
  };

  const openConvertModal = (wallet_id) => {
    setSelectedWalletId(wallet_id);
    setConvertModal(true);
  };

  useEffect(() => {
    set_seo({}, "کیف پول");

    getWalletList();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <Swiper
        slidesPerView={1.2}
        spaceBetween={16}
        breakpoints={{
          768: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 2.2,
          },
          1200: {
            slidesPerView: 2.2,
          },
          1400: {
            slidesPerView: 3.2,
          },
        }}
      >
        {notSallableWallets().map((item) => (
          <SwiperSlide>
            <WalletCard
              item={item}
              key={item.id}
              openConvertModal={openConvertModal}
            />
          </SwiperSlide>
        ))}

        {sallableWallets().map((item) => (
          <SwiperSlide>
            <WalletCardSalable
              item={item}
              key={item.id}
              walletsIdsList={walletsIdsList}
              refreshWallets={() => refreshWalletsInfo()}
              openConvertModal={openConvertModal}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div id="wallets">
        <div className="page-title mb-3 mt-5 mt-md-3">تاریخچه کیف پول ها</div>

        <Card className="no-radius dark-border" key={historyKey}>
          {wallets.length ? (
            <Tabs
              defaultActiveKey={`wallet_${wallets[0].id}`}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {wallets.map((item) => (
                <Tab
                  key={item.id}
                  eventKey={`wallet_${item.id}`}
                  title={item.currency.title}
                >
                  <WalletHistory wallet_id={item.id} />
                </Tab>
              ))}
            </Tabs>
          ) : (
            <>
              <NothingToShow text="موردی برای نمایش وجود ندارد" />
            </>
          )}

          {loading && <BoxLoading />}
        </Card>
      </div>

      <ConvertWalletBalanceModal
        wallets={wallets}
        selectedWalletId={selectedWalletId}
        show={convertModal}
        setShow={setConvertModal}
        refreshWallets={() => refreshWalletsInfo()}
      />
    </>
  );
}

export default Wallet;
